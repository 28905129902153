import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
    TERipple,
} from "tw-elements-react";
import utf8 from "utf8";
import { Apis } from "../../../api/index";
import { useAuthContext } from "../../../auth/context";
import NumberInputComponent from "../../../components/NumberInputComponent";
import { myConfig } from "../../../api/index";

const LoginModal = () => {
    const [isLogin, setIsLogIn] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const { setUserInfo, showLoginEmailModal, setShowLoginEmailModal } =
        useAuthContext();

    // Login/Register Data
    const [registerData, setRegisterData] = useState({
        email: "",
        username: "",
        password: "",
        full_name: "",
    });
    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
    });
    const [verifyEmail, setVerifyEmail] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifyNewPassword, setVerifyNewPassword] = useState("");
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [showForgottenModal, setShowForgottenModal] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [isEnteredEmail, setIsEnteredEmail] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isShowErrorCheckbox, setIsShowErrorCheckbox] = useState(false);

    const handleShowClick = () => {
        setIsShow((pre) => !pre);
    };

    const handlePassword = (e) => {
        const newLoginData = { ...loginData };
        setLoginData({ ...newLoginData, password: e.target.value });
        const newRegisterData = { ...registerData };
        setRegisterData({ ...newRegisterData, password: e.target.value });
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setIsShowErrorCheckbox(!event.target.checked);
    };

    // post Register/Login
    const postRegister = async () => {
        if (isLogin) {
            try {
                const response = await Apis.login(loginData);
                if (response.success) {
                    toast.success(
                        `${response.data.username} ログインに成功しました`
                    );
                    const user = response.data;
                    setUserInfo(user);
                    setShowLoginEmailModal(false);
                }
            } catch (error) {
                toast.error(
                    error?.message ? error.message : "エラーが発生しました。"
                );
                console.error(error);
            }
        } else {
            if (!registerData.full_name) {
                return toast.error("氏名の形式が正しくありません。");
            }
            if (verifyPassword !== registerData.password) {
                toast.error("パスワード合っていません");
            } else if (isChecked === true) {
                // const regexUsername = /^[A-Za-z0-9]{5,}$/;
                const bannedUsernames = ["admin"];
                // if (!regexUsername.test(registerData.username)) {
                //     toast.error("ユーザー名の形式が正しくありません");
                //     return;
                // }
                if (
                    bannedUsernames.some(
                        (item) => item === registerData.username
                    )
                ) {
                    toast.error("無効なユーザー名");
                    return;
                }
                try {
                    const response = await Apis.myPost(
                        `account/register/vcode`,
                        registerData
                    );
                    if (response.success) {
                        toast.success(
                            `${registerData.username} 登録に成功しました`
                        );

                        setShowLoginEmailModal(false);
                        setShowVerifyModal(true);
                    }
                } catch (error) {
                    toast.error(error.message);
                    console.error(error);
                }
            } else {
                setIsShowErrorCheckbox(true);
            }
        }
    };

    //If Verification is successfull
    const handleShowVerifyModal = (isSuccess) => {
        if (isSuccess) {
            setTimeout(() => {
                setShowVerifyModal(false);
                setIsLogIn(true);
                setShowLoginEmailModal(true);
            }, 500);
        }
    };
    const handleLoginUser = (data) => {
        setUserInfo(data);
        setShowVerifyModal(false);
    };

    // If Fogotten verification is successfull
    const handleShowFogorttenModal = (isSuccess) => {
        if (isSuccess) {
            setShowForgottenModal(false);
            setShowResetModal(true);
            setIsEnteredEmail(false);
            setVerifyEmail("");
        }
    };

    // Post if Forgotten email is entered
    const handleVerifyEmail = async () => {
        try {
            const response = await Apis.myPost(`account/forgot_password`, {
                email: verifyEmail,
            });
            if (response.success) {
                localStorage.setItem("emailVerify", verifyEmail);
                toast.success(response.data.message);
                setIsEnteredEmail(true);
                return;
            }
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
    };

    // Post password reset request
    const handleResetPassword = async () => {
        if (newPassword !== verifyNewPassword) {
            toast.error("パスワードが一致しません");
            return;
        }
        try {
            const response = await fetch(
                `${myConfig.apiUrl}/account/password_reset`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: localStorage.getItem("emailVerify"),
                        vcode: localStorage.getItem("code"),
                        new_password: utf8.encode(newPassword),
                    }),
                }
            );
            if (response.ok) {
                localStorage.removeItem("emailVerify");
                localStorage.removeItem("code");
                toast.success("パスワードが正常に変更されました");
                setShowResetModal(false);
                setShowLoginEmailModal(true);
                setIsLogIn(true);
                return;
            }
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
    };

    useEffect(() => {
        if (!showLoginEmailModal) {
            setIsLogIn(false);
            setLoginData({
                username: "",
                password: "",
            });
            if (!showVerifyModal) {
                setRegisterData({
                    email: "",
                    username: "",
                    password: "",
                    full_name: "",
                });
            }
            setVerifyPassword("");
            setIsShowErrorCheckbox(false);
            setIsChecked(false);
        }
    }, [showLoginEmailModal]);

    useEffect(() => {
        if (!showVerifyModal && !showLoginEmailModal) {
            setRegisterData({
                email: "",
                username: "",
                password: "",
                full_name: "",
            });
        }
    }, [showVerifyModal]);
    return (
        <>
            {showLoginEmailModal && (
                <TEModal
                    show={showLoginEmailModal}
                    setShow={setShowLoginEmailModal}
                    staticBackdrop
                >
                    <TEModalDialog
                        style={{
                            display: "flex",
                            WebkitTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%) translateZ(0)",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            maxHeight: "100%",
                            overflowY: "auto", // 縦方向の余剰コンテンツをスクロールできるように設定
                            outline: 0, // フォーカス時の青い枠線を消す
                        }}
                    >
                        <TEModalContent>
                            <TEModalBody
                                style={{
                                    maxHeight: "calc(100% - 0px)",
                                    overflowY: "auto",
                                }}
                                className="min-w-[350px] md:min-w-[400px]"
                            >
                                <div className="relative">
                                    <button
                                        type="button"
                                        className="absolute right-0 top-0 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        onClick={() => {
                                            const event = new Event(
                                                "cancelLoginModal"
                                            );
                                            window.dispatchEvent(event);
                                            setShowLoginEmailModal(false);
                                        }}
                                        aria-label="Close"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <div className="login mx-4 md:mx-8 mb-4 md:mb-10">
                                        <div className="flex justify-center relative">
                                            <Link
                                                alt="logo"
                                                className="logo"
                                                to=""
                                                onClick={() =>
                                                    setShowLoginEmailModal(
                                                        false
                                                    )
                                                }
                                            ></Link>
                                        </div>
                                        <div className="text-center text-[22px] font-medium">
                                            {isLogin ? "ログイン" : "新規登録"}
                                        </div>
                                        <div className="font-semibold mt-[20px]">
                                            {isLogin
                                                ? "アカウント登録がお済みでない方は、"
                                                : "メールアドレスですでに登録済みの方は、"}
                                            <Link
                                                className="text-blue-500 underline"
                                                to=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsLogIn((pre) => !pre);
                                                }}
                                            >
                                                こちら
                                            </Link>
                                            {isLogin
                                                ? "から新規登録できます"
                                                : "からログインできます"}
                                        </div>
                                        <form
                                            className="loginMainInfo mt-[30px]"
                                            action=""
                                        >
                                            <label className="font-medium text-zinc-400">
                                                Eメール
                                            </label>
                                            <div className="flex items-center">
                                                <img
                                                    alt="mail"
                                                    src="/image/email.png"
                                                    className="w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                                <input
                                                    value={loginData.username}
                                                    onChange={(e) => {
                                                        setLoginData((pre) => ({
                                                            ...pre,
                                                            username:
                                                                e.target.value,
                                                        }));
                                                        setRegisterData(
                                                            (pre) => ({
                                                                ...pre,
                                                                email: e.target
                                                                    .value,
                                                            })
                                                        );
                                                    }}
                                                    type="email"
                                                    id="mail"
                                                    name="mail"
                                                    className="w-full ml-3 focus:outline-none focus:border-none text-black peer-[1]: placeholder-slate-700 placeholder:font-medium bg-white"
                                                    placeholder="メールアドレスを入力"
                                                    autocomplete="off"
                                                ></input>
                                            </div>
                                            <div className="w-full h-[2px] bg-neutral-400 mt-1 peer-[1]-focused:bg-slate-900"></div>
                                            {!isLogin && (
                                                <div>
                                                    <label className="mt-[60px] font-medium text-zinc-400">
                                                        ユーザー名(半角英数字5文字以上)
                                                    </label>
                                                    <div className="flex items-center">
                                                        <img
                                                            alt="mail"
                                                            src="/image/user.png"
                                                            className="w-4 h-auto"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                            }}
                                                        ></img>
                                                        <input
                                                            value={
                                                                registerData.username
                                                            }
                                                            onChange={(e) => {
                                                                setRegisterData(
                                                                    (pre) => ({
                                                                        ...pre,
                                                                        username:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                );
                                                            }}
                                                            type="text"
                                                            className="w-full ml-3 focus:outline-none focus:border-none text-black peer-[1]: placeholder-slate-700 placeholder:font-medium bg-white"
                                                            placeholder="ユーザー名を入力"
                                                        ></input>
                                                    </div>
                                                    <div className="w-full h-[2px] bg-neutral-400 mt-1 peer-[1]-focused:bg-slate-900"></div>
                                                </div>
                                            )}
                                            {!isLogin && (
                                                <div>
                                                    <label className="mt-[60px] font-medium text-zinc-400">
                                                        氏名
                                                    </label>
                                                    <div className="flex items-center">
                                                        <img
                                                            alt="full_name"
                                                            src="/image/input.png"
                                                            className="w-4 h-auto"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                            }}
                                                        ></img>
                                                        <input
                                                            value={
                                                                registerData.full_name
                                                            }
                                                            onChange={(e) => {
                                                                setRegisterData(
                                                                    (pre) => ({
                                                                        ...pre,
                                                                        full_name:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                );
                                                            }}
                                                            type="text"
                                                            className="w-full ml-3 focus:outline-none focus:border-none text-black peer-[1]: placeholder-slate-700 placeholder:font-medium bg-white"
                                                            placeholder="氏名を入力"
                                                        ></input>
                                                    </div>
                                                    <div className="w-full h-[2px] bg-neutral-400 mt-1 peer-[1]-focused:bg-slate-900"></div>
                                                </div>
                                            )}

                                            <label className="mt-[60px] font-medium text-zinc-400">
                                                パスワード
                                            </label>
                                            <div className="flex items-center">
                                                <img
                                                    alt="password"
                                                    src="/image/password.png"
                                                    className="w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                                <input
                                                    value={loginData.password}
                                                    onChange={handlePassword}
                                                    type={
                                                        isShow
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="password"
                                                    name="password"
                                                    className="w-full ml-3 focus:outline-none focus:border-none text-black placeholder-slate-900 placeholder:font-medium bg-white"
                                                    placeholder="パスワードを入力"
                                                    autocomplete="off"
                                                ></input>
                                                <img
                                                    alt="eye"
                                                    src={
                                                        isShow
                                                            ? "/image/eye-open.png"
                                                            : "/image/eye-close.png"
                                                    }
                                                    onClick={handleShowClick}
                                                    className="cursor-pointer w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                            </div>
                                            <div className="w-full h-[2px] bg-neutral-400 mt-1"></div>
                                            {!isLogin && (
                                                <>
                                                    <label className="mt-[60px] font-medium text-zinc-400">
                                                        パスワードを再入力してください
                                                    </label>
                                                    <div className="flex items-center">
                                                        <img
                                                            alt="password"
                                                            src="/image/password.png"
                                                            className="w-4 h-auto"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                            }}
                                                        ></img>
                                                        <input
                                                            value={
                                                                verifyPassword
                                                            }
                                                            onChange={(e) =>
                                                                setVerifyPassword(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            type={
                                                                isShow
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            className="w-full ml-3 focus:outline-none focus:border-none text-black placeholder-slate-900 placeholder:font-medium bg-white"
                                                            placeholder="パスワード再入力"
                                                        ></input>
                                                        <img
                                                            alt="eye"
                                                            src={
                                                                isShow
                                                                    ? "/image/eye-open.png"
                                                                    : "/image/eye-close.png"
                                                            }
                                                            onClick={
                                                                handleShowClick
                                                            }
                                                            className="cursor-pointer w-4 h-auto"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div className="w-full h-[2px] bg-neutral-400 mt-1"></div>
                                                </>
                                            )}
                                            {isLogin && (
                                                <Link
                                                    className="fogetPassword float-right mr-2 mt-3"
                                                    to=""
                                                    onClick={() => {
                                                        setShowLoginEmailModal(
                                                            false
                                                        );
                                                        setShowForgottenModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    パスワードを忘れた方
                                                </Link>
                                            )}
                                            <br />
                                            <br />
                                            {!isLogin && (
                                                <div>
                                                    <label className="flex items-start">
                                                        <div className="h-full w-fit pr-2 flex justify-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    isChecked
                                                                }
                                                                onChange={
                                                                    handleCheckboxChange
                                                                }
                                                                className="form-checkbox h-5 w-5 text-indigo-600"
                                                            />
                                                        </div>
                                                        <span className="">
                                                            <Link
                                                                to="https://degime.net/d-home/terms_of_use/"
                                                                target="_blank"
                                                                className="text-indigo-600"
                                                            >
                                                                利用規約
                                                            </Link>
                                                            と
                                                            <Link
                                                                to="https://degime.net/d-home/c-policy/"
                                                                target="_blank"
                                                                className="text-indigo-600"
                                                            >
                                                                プライバシーポリシー
                                                            </Link>
                                                            に同意します
                                                        </span>
                                                    </label>
                                                    {isShowErrorCheckbox && (
                                                        <p className="text-red-500 text-sm mt-2">
                                                            チェックを入れてください
                                                        </p>
                                                    )}
                                                </div>
                                            )}

                                            <div
                                                className="w-full text-white text-center cursor-pointer bg-sky-400 hover:bg-sky-500 active:bg-sky-600 transition duration-75 ease-in-out py-3 rounded-full text-base mt-16"
                                                onClick={postRegister}
                                            >
                                                {isLogin
                                                    ? "ログイン"
                                                    : "新規登録"}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </TEModalBody>
                        </TEModalContent>
                    </TEModalDialog>
                </TEModal>
            )}

            <TEModal
                show={showVerifyModal}
                setShow={setShowVerifyModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                送られてくるメールで、コードを確認して入力してください。
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowVerifyModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>
                            <NumberInputComponent
                                email={registerData.email}
                                isSuccessful={handleShowVerifyModal}
                                handleLoginUser={handleLoginUser}
                            />
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            <TEModal
                show={showForgottenModal}
                setShow={setShowForgottenModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                {isEnteredEmail
                                    ? "メールコードを確認してください。"
                                    : "メールアドレスを入力"}
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => {
                                    setShowForgottenModal(false);
                                    setVerifyEmail("");
                                    localStorage.removeItem("emailVerify");
                                    setIsEnteredEmail(false);
                                }}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>
                            {isEnteredEmail ? (
                                <NumberInputComponent
                                    email={verifyEmail}
                                    isForgotten={showForgottenModal}
                                    isSuccessful={handleShowFogorttenModal}
                                />
                            ) : (
                                <>
                                    <div className="w-full flex justify-center flex-col">
                                        <label htmlFor="verifyEmail">
                                            メールアドレス
                                        </label>
                                        <input
                                            value={verifyEmail}
                                            onChange={(e) =>
                                                setVerifyEmail(e.target.value)
                                            }
                                            type="email"
                                            id="verifyEmail"
                                            className="border p-2"
                                        />
                                    </div>
                                    <div className="flex justify-center mt-10 mb-5">
                                        <TERipple>
                                            <button
                                                type="button"
                                                className="px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white"
                                                onClick={handleVerifyEmail}
                                            >
                                                確 認
                                            </button>
                                        </TERipple>
                                    </div>
                                </>
                            )}
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            <TEModal
                show={showResetModal}
                setShow={setShowResetModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                メールコードの確認してください。
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => {
                                    setShowResetModal(false);
                                    localStorage.removeItem("emailVerify");
                                    localStorage.removeItem("code");
                                }}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>
                            <div className="w-full flex justify-center flex-col">
                                <label htmlFor="newPassword">
                                    新しいパスワードを入力
                                </label>
                                <input
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    type="password"
                                    id="newPassword"
                                    className="border p-2"
                                />
                            </div>
                            <div className="w-full flex justify-center flex-col">
                                <label htmlFor="verifyNewPassword">
                                    新しいパスワードを再入力
                                </label>
                                <input
                                    value={verifyNewPassword}
                                    onChange={(e) =>
                                        setVerifyNewPassword(e.target.value)
                                    }
                                    type="password"
                                    id="verifyNewPassword"
                                    className="border p-2"
                                />
                            </div>
                            <div className="flex justify-center mt-10 mb-5">
                                <TERipple>
                                    <button
                                        type="button"
                                        className="px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white"
                                        onClick={handleResetPassword}
                                    >
                                        確 認
                                    </button>
                                </TERipple>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </>
    );
};

export default LoginModal;
